<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4831 1.50565C14.9454 1.80787 15.0751 2.4276 14.7729 2.88986L8.0732 13.1373C7.90914 13.3883 7.64115 13.5524 7.34306 13.5844C7.04497 13.6164 6.74823 13.513 6.5346 13.3027L2.2564 9.09028C1.86286 8.70279 1.85796 8.06964 2.24544 7.67611C2.63293 7.28257 3.26608 7.27766 3.65961 7.66515L7.06754 11.0207L13.0989 1.79543C13.4011 1.33317 14.0209 1.20343 14.4831 1.50565Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheck'
}
</script>
